<script>
import { mapGetters } from 'vuex';
import DateTimePicker from '../components/DateTimePicker.vue';
import TheSystemMessage from '../components/TheSystemMessage.vue';

export default {
  name: 'OEMSystemPage',
  components: {
    DateTimePicker,
    TheSystemMessage,
  },
  data() {
    return {
      startEnabled: false,
      startTime: null,
      endEnabled: false,
      endTime: null,
      messageEnabled: false,
      statusMessage: '',
      statusVariant: 'info',
      variants: [
        { value: 'info', text: 'Info' },
        { value: 'success', text: 'Success' },
        { value: 'warning', text: 'Warning' },
        { value: 'danger', text: 'Danger' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      serverStatus: 'ui/status',
      serverOfflineStart: 'ui/offlineStart',
      serverOfflineEnd: 'ui/offlineEnd',
    }),
    disableSaveButton() {
      if (this.startEnabled && !this.startTime) return true;
      if (this.endEnabled && !this.endTime) return true;
      if (this.messageEnabled && !this.statusMessage) return true;
      return false;
    },
  },
  mounted() {
    this.$store.dispatch('ui/startLoading')
      .then(() => this.$store.dispatch('ui/getStatus'))
      .then(() => {
        if (this.serverOfflineStart) {
          this.startEnabled = true;
          this.startTime = this.serverOfflineStart.toISOString();
        } else {
          this.startEnabled = false;
          this.startTime = null;
        }
        if (this.serverOfflineEnd) {
          this.endEnabled = true;
          this.endTime = this.serverOfflineEnd.toISOString();
        } else {
          this.endEnabled = false;
          this.endTime = null;
        }
        this.statusMessage = this.serverStatus.message || '';
        this.statusVariant = this.serverStatus.variant || 'info';
        this.messageEnabled = !!this.statusMessage && !!this.statusVariant;
      })
      .finally(() => this.$store.dispatch('ui/stopLoading'));
  },
  methods: {
    setMaintenanceMode() {
      this.$http({
        method: 'PUT',
        url: '/status',
        data: {
          statusMessage: this.messageEnabled ? this.statusMessage : null,
          statusVariant: this.messageEnabled ? this.statusVariant : null,
          offlineStart: this.startEnabled ? this.startTime : null,
          offlineEnd: this.endEnabled ? this.endTime : null,
        },
      })
        .then(() => this.$store.dispatch('ui/getStatus'))
        .then(() => this.$bvToast.toast('Status updated successfully', {
          title: 'Success',
          variant: 'success',
          toaster: 'b-toaster-bottom-center',
        }));
    },
    insertVariable(varName) {
      if (this.messageEnabled) {
        this.statusMessage += `{{ ${varName} }}`;
      }
    },
  },
};
</script>
<template>
  <b-container>

    <b-row>
      <b-col cols=4>
        <h2>System Settings</h2>
      </b-col>
    </b-row>

    <!-- Begin mainrtenance mode settings -->
    <b-card title='Maintenance Mode'>

      <b-row align-h='end'>
        <b-col cols='auto'>
          <span class='text-muted'>All dates and times are local time</span>
        </b-col>
      </b-row>

      <!-- Offline Period Scheduling -->
      <b-row class='mt-2'>
        <b-col lg='6'>
          <b-checkbox v-model='startEnabled' switch>
            Schedule an Offline Period
          </b-checkbox>
        </b-col>

        <b-col lg='6'>
          <date-time-picker v-bind:disabled='!startEnabled'
                            v-model='startTime'
                            future />
        </b-col>
      </b-row>

      <!-- Offline Period End -->
      <b-row class='mt-3'>
        <b-col lg='6'>
          <b-checkbox v-model='endEnabled' v-bind:disabled='!startEnabled' switch>
            Automatically end offline period
          </b-checkbox>
        </b-col>

        <b-col lg='6'>
          <date-time-picker v-model='endTime'
                            v-bind:disabled='!endEnabled || !startEnabled'
                            v-bind:after='startTime' />
        </b-col>
      </b-row>

      <!-- System Message Switch -->
      <b-row class='mt-3'>
        <b-col cols='6'>
          <b-checkbox v-model='messageEnabled' switch>
            Set a system message
          </b-checkbox>
        </b-col>

        <b-col cols='auto' />
      </b-row>

      <!-- Message formatting info -->
      <b-row>
        <b-col cols='12'>
          <p class='text-muted' v-pre>
            Variables can be added into the message by enclosing them in double curly brackets
            <code>{{ }}</code>.
            For example, to display the start time of the offline period, use
            <code>{{ start-time }}</code>.
          </p>
          <b-collapse id='sys-msg-vars'>
            Supported variables:
            <ul class='list-untyled card-columns'>
              <li><code @click="insertVariable('start-time')">start-time</code></li>
              <li><code @click="insertVariable('start-date')">start-date</code></li>
              <li><code @click="insertVariable('start-datetime')">start-datetime</code></li>
              <li><code @click="insertVariable('end-time')">end-time</code></li>
              <li><code @click="insertVariable('end-date')">end-date</code></li>
              <li><code @click="insertVariable('end-datetime')">end-datetime</code></li>
              <li><code @click="insertVariable('duration')">duration</code></li>
              <li><code @click="insertVariable('hours')">hours</code></li>
              <li><code @click="insertVariable('mins')">mins</code></li>
            </ul>
          </b-collapse>
          <b-link v-b-toggle.sys-msg-vars>Show supported variables</b-link>
        </b-col>
      </b-row>

      <!-- System Message Selector -->
      <b-row>
        <b-col md='10'>
          <b-input placeholder="Set a message to display"
                   v-model='statusMessage'
                   v-bind:disabled='!messageEnabled' />
        </b-col>

        <b-col md='2'>
          <b-form-select :options='variants'
                         v-model='statusVariant'
                         v-bind:disabled='!messageEnabled' />
        </b-col>

      </b-row>

      <!-- System Message Preview -->
      <b-row v-if='!!statusMessage && messageEnabled' cols='12' class='mt-2' no-gutters>
        <b-col>
          Preview:
        </b-col>
        <b-col cols='12'>
          <the-system-message show
                              :show-close="false"
                              v-bind:message="statusMessage"
                              v-bind:variant="statusVariant"
                              v-bind:offline-start="startTime"
                              v-bind:offline-end="endTime" />
        </b-col>
      </b-row>

      <b-row align-h='end' class='mt-3'>
        <b-col md='4'>
          <b-btn @click='setMaintenanceMode' v-bind:disabled='disableSaveButton' block>Save</b-btn>
        </b-col>
      </b-row>

    </b-card>
    <!-- End maintenance mode settings -->

  </b-container>
</template>

<style scoped>
  code {
    white-space: nowrap !important;
  }
</style>
