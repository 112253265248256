<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheSystemMessage',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: null,
    },
    showClose: {
      type: Boolean,
      required: false,
      default: null,
    },
    message: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    offlineStart: {
      type: String,
      required: false,
      default: null,
    },
    offlineEnd: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      storeStatus: 'ui/status',
      storeOfflineStart: 'ui/offlineStart',
      storeOfflineEnd: 'ui/offlineEnd',
    }),
    msgVariant() {
      if (this.variant === null) {
        return this.storeStatus.variant;
      }
      return this.variant;
    },
    msgOfflineStart() {
      if (this.offlineStart === null) {
        return this.storeOfflineStart;
      }
      return new Date(this.offlineStart);
    },
    msgOfflineEnd() {
      if (this.offlineEnd === null) {
        return this.storeOfflineEnd;
      }
      return new Date(this.offlineEnd);
    },
    msg() {
      if (this.message === null) {
        return this.storeStatus ? this.storeStatus.message : null;
      }
      return this.message;
    },
    formattedMessage() {
      if (!this.msg || !this.msgVariant) {
        return '';
      }
      let formattedString = this.msg;
      if (this.msgOfflineStart) {
        formattedString = formattedString.replace('{{ start-datetime }}', this.$d(this.msgOfflineStart, 'long'));
        formattedString = formattedString.replace('{{ start-date }}', this.$d(this.msgOfflineStart, 'date'));
        formattedString = formattedString.replace('{{ start-time }}', this.$d(this.msgOfflineStart, 'time'));
      }
      if (this.msgOfflineEnd) {
        formattedString = formattedString.replace('{{ end-datetime }}', this.$d(this.msgOfflineEnd, 'long'));
        formattedString = formattedString.replace('{{ end-date }}', this.$d(this.msgOfflineEnd, 'date'));
        formattedString = formattedString.replace('{{ end-time }}', this.$d(this.msgOfflineEnd, 'time'));

        // Duration will be in seconds
        const duration = (this.msgOfflineEnd - this.msgOfflineStart) / 1E3;

        // Convert to hours and minutes
        let durationHours = Math.floor(duration / (60 ** 2));
        let durationMins = Math.floor(duration / (60 ** 1)) - (durationHours * 60);

        formattedString = formattedString.replace('{{ hours }}', durationHours);
        formattedString = formattedString.replace('{{ mins }}', durationMins);

        // Pad the hours and minutes
        if (durationHours < 10) {
          durationHours = `0${durationHours}`;
        }
        if (durationMins < 10) {
          durationMins = `0${durationMins}`;
        }

        // Format it
        formattedString = formattedString.replace('{{ duration }}', `${durationHours}:${durationMins}`);
      }
      return formattedString;
    },
    shown() {
      if (this.show !== null) {
        return this.show;
      }
      if (!this.storeStatus) {
        return false;
      }
      return !!this.msg && !!this.msgVariant;
    },
    dismissable() {
      if (this.showClose !== null) {
        return this.showClose;
      }
      return this.$route.matched.some(route => route.meta.navbar);
    },
  },
};
</script>

<template>
  <b-container>
    <b-alert v-bind:variant="msgVariant || ''"
             v-bind:show="shown"
             v-bind:dismissible='dismissable'>
      <fa-icon v-if="msgVariant === 'success'" icon='check-circle' />
      <fa-icon v-else-if="msgVariant === 'warning'" icon='exclamation-triangle' />
      <fa-icon v-else-if="msgVariant === 'danger'" icon='exclamation-circle' />
      <fa-icon v-else-if="msgVariant === 'info'" icon='info-circle' />
      {{ formattedMessage }}
    </b-alert>
  </b-container>
</template>
