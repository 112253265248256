<script>
export default {
  name: 'DateTimePicker',
  props: {
    value: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    utc: {
      type: Boolean,
      required: false,
      default: false,
    },
    future: {
      type: Boolean,
      required: false,
      default: false,
    },
    after: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      date: null,
      time: null,
    };
  },
  computed: {
    dateOutput() {
      // If either the time or the date has not been entered, return null
      if (this.disabled || !this.date || !this.time) {
        return null;
      }

      // Split the date into an array of year, month, date
      const dateSplit = this.date
        .split('-')
        .map(x => Number.parseInt(x, 10));

      // Split the time into an array of hour,minute,second
      const timeSplit = this.time
        .split(':')
        .map(x => Number.parseInt(x, 10));

      // Convert this into a date object
      const datetime = new Date();
      datetime.setUTCMilliseconds(0);

      // Set the year, month, date, hours, mins, and seconds on the date object
      if (this.utc) {
        datetime.setUTCFullYear(dateSplit[0]);
        datetime.setUTCMonth(dateSplit[1] - 1);
        datetime.setUTCDate(dateSplit[2]);
        datetime.setUTCHours(timeSplit[0]);
        datetime.setUTCMinutes(timeSplit[1]);
        datetime.setUTCSeconds(timeSplit[2]);
      } else {
        datetime.setFullYear(dateSplit[0]);
        datetime.setMonth(dateSplit[1] - 1);
        datetime.setDate(dateSplit[2]);
        datetime.setHours(timeSplit[0]);
        datetime.setMinutes(timeSplit[1]);
        datetime.setSeconds(timeSplit[2]);
      }

      return datetime;
    },
    output() {
      // Return as an ISO string
      return this.dateOutput.toISOString();
    },
    afterDate() {
      if (this.after) {
        return new Date(this.after);
      }
      return null;
    },
    minDate() {
      if (this.afterDate) {
        const year = this.pad(this.afterDate.getFullYear(), 4);
        const month = this.pad(this.afterDate.getMonth() + 1, 2);
        const date = this.pad(this.afterDate.getDate(), 2);
        return `${year}-${month}-${date}`;
      }
      if (this.future) {
        const now = new Date();
        const year = this.pad(now.getFullYear(), 4);
        const month = this.pad(now.getMonth() + 1, 2);
        const date = this.pad(now.getDate(), 2);
        return `${year}-${month}-${date}`;
      }
      return undefined;
    },
    minTime() {
      if (this.afterDate) {
        const hour = this.pad(this.afterDate.getHours(), 2);
        const min = this.pad(this.afterDate.getMinutes(), 2);
        const sec = this.pad(this.afterDate.getSeconds(), 2);
        return `${hour}:${min}:${sec}`;
      }
      if (this.future) {
        const now = new Date();
        const hour = this.pad(now.getHours(), 2);
        const min = this.pad(now.getMinutes(), 2);
        const sec = this.pad(now.getSeconds(), 2);
        return `${hour}:${min}:${sec}`;
      }
      return undefined;
    },
    timeValidationState() {
      if (!this.dateOutput) {
        return null;
      }
      if (this.after && this.dateOutput < new Date(this.after)) {
        return false;
      }
      if (this.future && this.dateOutput < new Date()) {
        return false;
      }
      return null;
    },
  },
  watch: {
    output() {
      // When the value changes, emit an 'input' event if validation passed
      if (this.timeValidationState === null) {
        this.$emit('input', this.output);
      } else {
        this.$emit('input', null);
      }
    },
    value() {
      this.updateFromValue();
    },
  },
  methods: {
    pad(x, n) {
      let str = `${x}`;
      const padLen = n - str.length;
      for (let i = 0; i < padLen; i += 1) {
        str = `0${str}`;
      }
      return str;
    },
    updateFromValue() {
      if (this.value) {
        let year;
        let month;
        let date;
        let hour;
        let min;
        let sec;
        const dateVal = new Date(this.value);
        if (this.utc) {
          year = this.pad(dateVal.getUTCFullYear(), 4);
          month = this.pad(dateVal.getUTCMonth() + 1, 2);
          date = this.pad(dateVal.getUTCDate(), 2);
          hour = this.pad(dateVal.getUTCHours(), 2);
          min = this.pad(dateVal.getUTCMinutes(), 2);
          sec = this.pad(dateVal.getUTCSeconds(), 2);
        } else {
          year = this.pad(dateVal.getFullYear(), 4);
          month = this.pad(dateVal.getMonth() + 1, 2);
          date = this.pad(dateVal.getDate(), 2);
          hour = this.pad(dateVal.getHours(), 2);
          min = this.pad(dateVal.getMinutes(), 2);
          sec = this.pad(dateVal.getSeconds(), 2);
        }
        this.date = `${year}-${month}-${date}`;
        this.time = `${hour}:${min}:${sec}`;
      }
    },
  },
  created() {
    this.updateFromValue();
  },
};
</script>

<template>
  <div>
    <div class='d-inline-block w-50'>
      <b-datepicker v-model='date'
                    v-bind:disabled='disabled'
                    v-bind:min="minDate"
                    v-bind:state="timeValidationState" />
    </div>

    <div class='d-inline-block w-50'>
      <b-timepicker v-model='time'
                    v-bind:disabled='disabled'
                    v-bind:state="timeValidationState" />
    </div>
    <b-form-invalid-feedback v-bind:state="timeValidationState">
      <slot>
        <span v-if='after'>
          The provided time must be after {{ minTime }}
        </span>
        <span v-else-if='future'>
          The provided time must be in the future
        </span>
      </slot>
    </b-form-invalid-feedback>
  </div>
</template>
